import React from 'react';
import UserUpdateUI from "../../components/admin/users/components/UserListUpdate";
import PersistentDrawerLeft from "../../components/reusable/admin/Main";
const UserEdit = () => {
    return (
        <div>
            <PersistentDrawerLeft>
                <UserUpdateUI />
            </PersistentDrawerLeft>
        </div>
    );
};

export default UserEdit;