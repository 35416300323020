import React,{useState, useEffect} from 'react';
import { Box } from '@mui/material';

const OrderViewTwoTable = ({selecteduitable,filteredprintexl,companies,styles,selectedCompany,columnGroups
  ,isDiscountShow, isGstShow }:any) => {
    const borderColor = selecteduitable === "SOHAM FIRM" ? "1px solid rgb(166, 166, 192)" : "1px solid rgb(175 193 123 / 49%)";
    const borderBottomColor = selecteduitable === "SOHAM FIRM" ? "rgb(166, 166, 192)" : "rgb(175 193 123 / 78%)";
    const [columns,setColumns] = useState(["Pack", "Rate", "Dis.%", "Dis. Amt", "GST", "Qty", "Final Amount"]);
    const [columnGroupss,setColumnGroupss] = useState([...columnGroups]);
    const styles2 = {
        table: {
          width: '100%',
          borderCollapse: 'collapse' as 'collapse' | 'separate',
          fontFamily: 'Arial, sans-serif',
          border: `2px solid ${borderColor}`, // Dynamically set border color
        },
    };

    useEffect(()=>{
      let column =  isDiscountShow && isGstShow
      ? [...["Pack", "Rate", "Dis.%", "Dis. Amt", "GST", "Qty", "Final Amount"]]
      : isDiscountShow && !isGstShow
      ? [...["Pack", "Rate", "Dis.%", "Dis. Amt", "Qty", "Final Amount"]]
      :  !isDiscountShow && isGstShow  ?[...["Pack", "Rate",  "GST", "Qty", "Final Amount"]] :
      [...["Pack", "Rate",  "Qty", "Final Amount"]] ;
      setColumns(column);
      let columnGroupsss = columnGroups
      if(isDiscountShow && isGstShow){
        columnGroupsss = columnGroups
      }else if(isDiscountShow && !isGstShow){
        columnGroupsss =  [
          { label: "Pack", colSpan: companies && companies.length > 0 ? companies.filter((c:any) =>
            selectedCompany ? (
              selectedCompany.includes(c.id) 
            ) : true
          ).length:3 },
          { label: "Rate", colSpan: companies && companies.length > 0 ? companies.filter((c:any) =>
            selectedCompany ? (
              selectedCompany.includes(c.id) 
            ) : true
          ).length:3 },
          { label: "Dis. %", colSpan: companies && companies.length > 0 ? companies.filter((c:any) =>
            selectedCompany ? (
              selectedCompany.includes(c.id) 
            ) : true
          ).length:3 },
          { label: "Dis. Amt", colSpan: companies && companies.length > 0 ? companies.filter((c:any) =>
            selectedCompany ? (
              selectedCompany.includes(c.id) 
            ) : true
          ).length:3 },
          { label: "Qty", colSpan: companies && companies.length > 0 ? companies.filter((c:any) =>
            selectedCompany ? (
              selectedCompany.includes(c.id) 
            ) : true
          ).length:3 },
          { label: "Final Amount", colSpan: companies && companies.length > 0 ? companies.filter((c:any) =>
            selectedCompany ? (
              selectedCompany.includes(c.id) 
            ) : true
          ).length:3 }
        ]
      }else if(!isDiscountShow && isGstShow ){
        columnGroupsss =  [
          { label: "Pack", colSpan: companies && companies.length > 0 ? companies.filter((c:any) =>
            selectedCompany ? (
              selectedCompany.includes(c.id) 
            ) : true
          ).length:3 },
          { label: "Rate", colSpan: companies && companies.length > 0 ? companies.filter((c:any) =>
            selectedCompany ? (
              selectedCompany.includes(c.id) 
            ) : true
          ).length:3 },
          { label: "GST", colSpan: companies && companies.length > 0 ? companies.filter((c:any) =>
            selectedCompany ? (
              selectedCompany.includes(c.id) 
            ) : true
          ).length:3 },
          { label: "Qty", colSpan: companies && companies.length > 0 ? companies.filter((c:any) =>
            selectedCompany ? (
              selectedCompany.includes(c.id) 
            ) : true
          ).length:3 },
          { label: "Final Amount", colSpan: companies && companies.length > 0 ? companies.filter((c:any) =>
            selectedCompany ? (
              selectedCompany.includes(c.id) 
            ) : true
          ).length:3 }
        ]
      }else{
        columnGroupsss =  [
          { label: "Pack", colSpan: companies && companies.length > 0 ? companies.filter((c:any) =>
            selectedCompany ? (
              selectedCompany.includes(c.id) 
            ) : true
          ).length:3 },
          { label: "Rate", colSpan: companies && companies.length > 0 ? companies.filter((c:any) =>
            selectedCompany ? (
              selectedCompany.includes(c.id) 
            ) : true
          ).length:3 },
          { label: "Qty", colSpan: companies && companies.length > 0 ? companies.filter((c:any) =>
            selectedCompany ? (
              selectedCompany.includes(c.id) 
            ) : true
          ).length:3 },
          { label: "Final Amount", colSpan: companies && companies.length > 0 ? companies.filter((c:any) =>
            selectedCompany ? (
              selectedCompany.includes(c.id) 
            ) : true
          ).length:3 }
        ]
      }
      setColumnGroupss([...columnGroupsss]);
    },[isDiscountShow,isGstShow]);
  
    return (
        <Box
        sx={{
          overflowX: 'auto',
          maxHeight: '500px',
        }}
        className='product-code-cell-code'
      >
        <table style={{ ...styles2.table}}>
          <tbody>
            {companies && companies.length > 0 && (
              <>
                <tr style={selecteduitable === "SOHAM FIRM" ? styles.sohamtableHead : styles.tableHead}>
                  <td
                    style={{
                      ...styles.tableCell,
                      textAlign: 'center',
                      border: borderColor,
                      borderBottomColor: borderBottomColor,
                    }}
                  >
                    Sr. No.
                  </td>
      
                  <td
                    style={{
                      ...styles.tableCell,
                      ...styles.tableCell3,
                      width: '200px',
                      maxWidth: '200px',
                      whiteSpace: "normal",
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      border: borderColor,
                      borderBottomColor: borderBottomColor,
                    }}
                    className="product-code-cell"
                  >
                    Name of Chemicals
                  </td>
      
                  {columnGroupss.map((group: any) => (
                    <td
                      key={group.label}
                      style={{
                        ...styles.tableCell,
                        textAlign: 'center',
                        border: borderColor,
                        borderBottomColor: borderBottomColor,
                      }}
                      colSpan={group.colSpan}
                    >
                      {group.label}
                    </td>
                  ))}
                </tr>
      
                <tr style={selecteduitable === "SOHAM FIRM" ? styles.sohamtableHead : styles.tableHead}>
                  <td
                    style={{
                      ...styles.tableCell,
                      textAlign: 'center',
                      border: borderColor,
                      borderBottomColor: borderBottomColor,
                    }}
                  ></td>
                  <td
                    style={{
                      ...styles.tableCell,
                      ...styles.tableCell3,
                      width: '200px',
                      maxWidth: '200px',
                      whiteSpace: "normal",
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      border: borderColor,
                      borderBottomColor: borderBottomColor,
                    }}
                    className="product-code-cell"
                  ></td>
      
                  {columns.map((header, headerIndex) =>
                    companies
                      .filter((c: any) =>
                        selectedCompany ? selectedCompany.includes(c.id) : true
                      )
                      .map((company: any, companyIndex:any) => (
                        <td
                          key={`${header}-${company.id}`}
                          style={{
                            ...styles.tableCell,
                            textAlign: 'center',
                            border: borderColor,
                            borderBottomColor: borderBottomColor,
                          }}
                        >
                          {company.name}
                        </td>
                      ))
                  )}
                </tr>
              </>
            )}
      
            {filteredprintexl.map((product: any, index: number) => (
              <tr key={product.id} style={styles.tableRow}>
                <td style={styles.tableCell6}>{index + 1}</td>
                <td
                  style={{
                    ...styles.tableCell2,
                    ...styles.tableCellexl,
                    width: '200px',
                    maxWidth: '200px',
                    whiteSpace: "normal",
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                  className="product-code-cell"
                >
                  {product.productName}<br/>
                  {product.description}

                </td>
      
                {companies
                  .filter((c: any) =>
                    selectedCompany ? selectedCompany.includes(c.id) : true
                  )
                  .map((company: any) => (
                    <td key={`pack-${company.id}`} style={styles.tableCell2}>
                      {product[`${company.name.toLowerCase()}Rate`] ? product.pack : '-'}
                    </td>
                  ))}
      
                {companies
                  .filter((c: any) =>
                    selectedCompany ? selectedCompany.includes(c.id) : true
                  )
                  .map((company: any) => (
                    <td key={`rate-${company.id}`} style={styles.tableCell2}>
                        {
                  isDiscountShow && isGstShow
                  ? product[`${company.name.toLowerCase()}Rate`] || '0'
                  : isDiscountShow && !isGstShow
                  ? product[`${company.name.toLowerCase()}Rate`] || '0'
                  :  !isDiscountShow && isGstShow  ?
                  product[`${company.name.toLowerCase()}DiscountAmt`] || '0' : 
                   (((product[`${company.name.toLowerCase()}FinalAmt`] || 0 )/ 
                   product[`${company.name.toLowerCase()}Qty`] || 0).toFixed(2))
                 
                }
                      {/* {product[`${company.name.toLowerCase()}Rate`] || '0'} */}
                    </td>
                  ))}
      
                {
                  isDiscountShow && <>
                  {companies
                            .filter((c: any) =>
                              selectedCompany ? selectedCompany.includes(c.id) : true
                            )
                            .map((company: any) => (
                              <td key={`discountPercent-${company.id}`} style={styles.tableCell2}>
                                {product[`${company.name.toLowerCase()}DiscountPercent`] || '0'}%
                              </td>
                            ))}
                
                          {companies
                            .filter((c: any) =>
                              selectedCompany ? selectedCompany.includes(c.id) : true
                            )
                            .map((company: any) => (
                              <td key={`discountAmt-${company.id}`} style={styles.tableCell2}>
                                {product[`${company.name.toLowerCase()}DiscountAmt`] || '0'}
                              </td>
                            ))}
                
                  </>
                }
               
               {
                isGstShow && <>
                {companies
                  .filter((c: any) =>
                    selectedCompany ? selectedCompany.includes(c.id) : true
                  )
                  .map((company: any) => (
                    <td key={`gst-${company.id}`} style={styles.tableCell2}>
                      {product[`${company.name.toLowerCase()}Gst`] || '0'}%
                    </td>
                  ))}
                </>
               }
                
      
                {companies
                  .filter((c: any) =>
                    selectedCompany ? selectedCompany.includes(c.id) : true
                  )
                  .map((company: any) => (
                    <td key={`Qty-${company.id}`} style={styles.tableCell2}>
                      {product[`${company.name.toLowerCase()}Qty`] || '0'}
                    </td>
                  ))}
      
                {companies
                  .filter((c: any) =>
                    selectedCompany ? selectedCompany.includes(c.id) : true
                  )
                  .map((company: any, index33: any) => (
                    <td
                      key={`finalAmt-${company.id}`}
                      style={
                        companies.filter((c: any) =>
                          selectedCompany ? selectedCompany.includes(c.id) : true
                        ).length == index33 + 1
                          ? styles.tableCell5
                          : styles.tableCell2
                      }
                    >
                      {product[`${company.name.toLowerCase()}FinalAmt`] || '0'}
                    </td>
                  ))}
              </tr>
            ))}
          </tbody>
        </table>
      </Box>
    )
}

export default OrderViewTwoTable;