import React, { useEffect, useState } from 'react';
import PersistentDrawerLeft from '../../../reusable/admin/Main';
import { Form, Input, Button, message, Row, Col } from "antd";
import { Box, Typography } from "@mui/material";
import { useNavigate, useParams } from 'react-router-dom';
import { BUTTON_STYLE } from "../../../../constants/style";
import { FormDiv, ButtonCust } from "../styles/Product.style";
import { users_editapi, users_update } from '../services/users';


const UserListUpdate = () => {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const { usersId } = useParams();
    const [isEdit, setIsEdit] = useState(false);
    const [datadepartmentId, setUserupdateId] = useState<any>({});

    useEffect(() => {
        if (usersId) {
            fetchUsersDetail();
        }
    }, [usersId]);

    const fetchUsersDetail = async () => {
        const response: any = await users_editapi(usersId);
        const users = response?.data || {};
        setUserupdateId(users);
        if (users) {
            form.setFieldsValue({
                fullName: users.fullName,
                mobileNo: users.mobileNo,
                email: users.email,
                addressInfo: users.addressInfo,
                GSTnumber: users.GSTnumber
            });
            setIsEdit(true);
        }
    };
    const onFinish = async (values: any) => {
        if (isEdit && usersId) {
            // If editing, call the update API
            try {
                const response = await users_update(values, usersId);
                if (response) {
                    message.success("User updated successfully!");
                } else {
                    message.error("Failed to update the User.");
                }
            } catch (error) {
                // console.error("Error updating User:", error);
                message.error("An error occurred while updating the User.");
            }
        } else {
            // console.log("Created Department:", values);
            message.success("New User added successfully!");
        }

        form.resetFields();
        navigate("/admin/User_list");
    };

    const onFinishFailed = (errorInfo: any) => {
        message.error("Failed to submit form. Please check your input.");
        console.log("Failed:", errorInfo);
    };

    const handleCreate = () => {
        navigate(`/admin/user_list`);
    };


    return (
        <FormDiv>
            <Box sx={{ display: "flex", justifyContent: "space-between", marginBottom: 2 }}>
                <Typography variant="h6"> {isEdit ? "Edit User List" : "Edit User List"}</Typography>
                <ButtonCust onClick={handleCreate} sx={BUTTON_STYLE}>
                    {isEdit ? "Edit User List" : "User List"}
                </ButtonCust>
            </Box>

            <Form
                form={form}
                name="userForm"
                layout="vertical"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
            >
                <Row gutter={16}>
                    {/* Full Name Field */}
                    <Col sm={24} md={12} lg={8} xl={8}>
                        <Form.Item
                            label="Full Name"
                            name="fullName"
                        // rules={[{ required: true, message: "Please input your full name!" }]}
                        >
                            <Input placeholder="Enter your full name" />
                        </Form.Item>
                    </Col>

                    {/* Mobile No Field */}
                    <Col sm={24} md={12} lg={8} xl={8}>
                        <Form.Item
                            label="Mobile No"
                            name="mobileNo"
                        // rules={[
                        //     { required: true, message: "Please input your mobile number!" },
                        //     { pattern: /^[0-9]{10}$/, message: "Please input a valid mobile number!" },
                        // ]}
                        >
                            <Input placeholder="Enter your mobile number" />
                        </Form.Item>
                    </Col>

                    {/* Email Field */}
                    <Col sm={24} md={12} lg={8} xl={8}>
                        <Form.Item
                            label="Email"
                            name="email"
                        // rules={[
                        //     { required: true, message: "Please input your email!" },
                        //     { type: 'email', message: "Please input a valid email!" },
                        // ]}
                        >
                            <Input placeholder="Enter your email" />
                        </Form.Item>
                    </Col>

                    <Col sm={24} md={12} lg={8} xl={8}>
                        <Form.Item
                            label="GST No"
                            name="GSTnumber"
                        // rules={[
                        //     { required: true, message: "Please input your GST number!" },
                        //     { pattern: /^[A-Za-z0-9]{15}$/, message: "Please input a valid GST number!" },
                        // ]}
                        >
                            <Input placeholder="Enter your GST number" />
                        </Form.Item>
                    </Col>
                    {/* Address Field */}
                    <Col sm={24} md={12} lg={16} xl={16}>
                        <Form.Item
                            label="Address Info"
                            name="addressInfo"
                        // rules={[{ required: true, message: "Please input your address!" }]}
                        >
                            <Input.TextArea placeholder="Enter your address" rows={5} />
                        </Form.Item>
                    </Col>

                    {/* GST No Field */}


                    {/* Submit Button */}
                    <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                                {isEdit ? "Update" : "Submit"}
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </FormDiv>
    );
};

export default UserListUpdate;
