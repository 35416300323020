export const STORE_ID = "1";
// export const API_BASE_URL = "http://localhost:8080/";
// export const IMG_BASE_URL = "http://localhost:8080/Images/";
export const WEB_BASE_URL = "https://uniquechemicalapp.devminds.co.in/";
// export const WEB_BASE_URL = "http://localhost:3000/";
export const API_BASE_URL = "https://uniquechemicalapi.devminds.co.in/";
export const IMG_BASE_URL = "https://uniquechemicalapi.devminds.co.in/Images/";
export const defaultUnit =  24;
export const defaultCategory = 1;
export const defaultSubCategory = 1;
export const insertProductAttriCount = 30;
export const insertQuotationAttriCount = 50;
export const insertQuotationAttriFetchCount = 50;
export const defaultDepartmentId = 1