import React from "react";
import { useNavigate } from "react-router-dom";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Rectangle,ResponsiveContainer
} from "recharts";

export default function TotalPricePerDay({ data }: any) {
  const navigate = useNavigate();

  const handleBarClick = (data: any, index: number) => {
    if (!data || !data.monthName) {
      console.error("Invalid data or monthName not found");
      return;
    }

    const monthName = data.monthName; // Example: "January"
    const currentYear = new Date().getFullYear();

    // Map month names to month indices (0 = January, 11 = December)
    const monthIndex = new Date(`${monthName} 1, ${currentYear}`).getMonth();

    if (isNaN(monthIndex)) {
      console.error("Invalid monthName:", monthName);
      return;
    }

    // Calculate start and end timestamps for the selected month
    const startOfMonth = new Date(currentYear, monthIndex, 1, 0, 0, 0, 0);
    const startTimestamp = startOfMonth.getTime();

    const endOfMonth = new Date(currentYear, monthIndex + 1, 0, 23, 59, 59, 999);
    const endTimestamp = endOfMonth.getTime();

   
    // Redirect to the desired route with start and end timestamps
    navigate(`/admin/quotation_list/${startTimestamp}/${endTimestamp}`);
  };

  return (
    data && (
      <ResponsiveContainer width="100%" height={300}>
        <BarChart
          // width={700}
          // height={300}
          data={data}
          margin={{
            top: 5,
            right: 0,
            left: 0,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="monthName" />
          <YAxis />
          <Tooltip />
          <Bar
            dataKey="totalQuotations"
            fill="#B3CDAD"
            activeBar={<Rectangle fill="pink" stroke="blue" />}
            onClick={(event, index) => handleBarClick(event, index)}
          />
        </BarChart>
      </ResponsiveContainer>
    )
  );
}
