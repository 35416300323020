import React from 'react';
import DepartmentUpdateUI from '../../components/admin/department/components/DepartmentUpdate';
import PersistentDrawerLeft from "../../components/reusable/admin/Main";
const DepartmentEdit = () => {
    return (
        <div>
            <PersistentDrawerLeft>
                <DepartmentUpdateUI />
            </PersistentDrawerLeft>
        </div>
    );
};

export default DepartmentEdit;