import React, { useState, useEffect } from 'react';
import { List, Collapse, Button, Typography, Row, Col, Space, Input, message } from 'antd';
import { DownOutlined, UpOutlined, DeleteOutlined,EditOutlined } from '@ant-design/icons';
import {delete_product} from "../services/order";

const { Panel } = Collapse;
const { Text } = Typography;

const ProductListView = ({ alreadyAddedQuotationPro,onSelectToOrder,departmentId }: any) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [visibleItems, setVisibleItems] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredProducts, setFilteredProducts] = useState(
    alreadyAddedQuotationPro.filter((e:any) => e.attribute.departmentId == departmentId) || []);
  useEffect(() => {
    // Reset filteredProducts whenever the data changes
    setFilteredProducts(alreadyAddedQuotationPro.filter((e:any) => e.attribute.departmentId == departmentId) || []);
  }, [alreadyAddedQuotationPro,departmentId]);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const handleLoadMore = () => {
    setVisibleItems((prev) => prev + 10);
  };

  const handleReset = () => {
    setVisibleItems(10);
    setSearchQuery('');
    setFilteredProducts(alreadyAddedQuotationPro.filter((e:any) => e.attribute.departmentId == departmentId) || []);
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);

    const filtered = alreadyAddedQuotationPro.filter((e:any) => e.attribute.departmentId == departmentId)?.filter((item: any) =>
      item.name.toLowerCase().includes(query)
    );
    setFilteredProducts(filtered);
    setVisibleItems(10); // Reset visible items count for the new filtered list
  };

  const handleDelete = async (id: string,quotationId :any,finalPrice:any) => {
    const updatedProducts = filteredProducts.filter((item: any) => item.attribute.id !== id);
    setFilteredProducts(updatedProducts);
    message.success('Product deleted successfully.');
    await delete_product(quotationId,id,finalPrice)

  };
  const setToUpdate = async (item:any) => {
    let newItem = {
      ...item,
      attribute:{...item.attribute, isUpdate: 1}
    }
    onSelectToOrder(newItem);
  } 

  const totalProducts = filteredProducts?.length || 0;
  const remainingItems = totalProducts - visibleItems;
  const visibleData = filteredProducts?.slice(0, visibleItems);

  return (
    alreadyAddedQuotationPro &&
    alreadyAddedQuotationPro.filter((e:any) => e.attribute.departmentId == departmentId).length > 0 && (
      <Collapse
        activeKey={isCollapsed ? '1' : undefined}
        style={{ margin: '0px' }}
        bordered
        expandIconPosition="end"
        ghost
      >
        <Panel
          key="1"
          header={
            <Button
              style={{ paddingLeft: '0px' }}
              type="link"
              onClick={toggleCollapse}
              icon={isCollapsed ? <UpOutlined /> : <DownOutlined />}
            >
              {isCollapsed
                ? 'Already Existing Product List'
                : 'Already Existing Product List'}
            </Button>
          }
        >
          {/* Search Input */}
          <div style={{ marginBottom: '16px' }}>
            <Input
              placeholder="Search products by name"
              value={searchQuery}
              onChange={handleSearch}
              allowClear
            />
          </div>

          {/* Header Row */}
          <Row gutter={16} style={{ width: '100%', marginBottom: '8px' }}>
            <Col span={5}>
              <Text strong>Name</Text>
            </Col>
            <Col span={2}>
              <Text>Code</Text>
            </Col>
            <Col span={3}>
              <Text>Company</Text>
            </Col>
            <Col span={2}>
              <Text>Qty</Text>
            </Col>
            <Col span={3}>
              <Text>Landing Price</Text>
            </Col>
            <Col span={2}>
              <Text>Price</Text>
            </Col>
            <Col span={2}>
              <Text>GST %</Text>
            </Col>
            <Col span={2}>
              <Text>Total</Text>
            </Col>
            <Col span={3}>
              <Text>Actions</Text>
            </Col>
          </Row>

          {/* Visible Data */}
          <List
            bordered
            dataSource={visibleData}
            renderItem={(item: any) => (
              <List.Item style={{ padding: '2px 0' }}>
                <Row gutter={16} style={{ width: '100%' }}>
                  <Col span={5} style={{ paddingLeft: '20px' }}>
                    <Text strong >
                      {item.name}
                    </Text>
                  </Col>
                  <Col span={2}>
                    <Text>{item.attribute.companyProductCode}</Text>
                  </Col>
                  <Col span={3}>
                    <Text>{item.attribute.companyName}</Text>
                  </Col>
                  <Col span={2}>
                    <Text>
                      {item.attribute.qty} {item.attribute.unitName}
                    </Text>
                  </Col>
                  <Col span={3}>
                    <Text>{item.attribute.landingPrice}</Text>
                  </Col>
                  <Col span={2}>
                    <Text>₹{item.attribute.price}</Text>
                  </Col>
                  <Col span={2}>
                    <Text>{item.attribute.gst_percentage}%</Text>
                  </Col>
                  <Col span={2}>
                    <Text>₹{item.attribute.totalPrice}</Text>
                  </Col>
                  <Col span={3}>
                    <Button
                      type="link"
                      icon={<DeleteOutlined />}
                      onClick={() => handleDelete(item.attribute.id,item.attribute.quotationId,item.attribute.totalPrice)}
                      danger
                    />
                     <Button
                      type="link"
                      icon={<EditOutlined />}
                      onClick={() => setToUpdate(item)}
                      danger
                    />
                  </Col>
                </Row>
              </List.Item>
            )}
          />

          {/* Load More and Reset Buttons */}
          <div style={{ textAlign: 'center', marginTop: '16px' }}>
            <Space>
              {visibleItems < totalProducts && (
                <Button type="primary" onClick={handleLoadMore}>
                  Load More ({remainingItems} more)
                </Button>
              )}
              {(visibleItems > 10 || searchQuery) && (
                <Button onClick={handleReset}>Reset</Button>
              )}
            </Space>
          </div>

          {/* Total Count Display */}
          <div style={{ textAlign: 'center', marginTop: '16px' }}>
            <Text type="secondary">
              Showing {Math.min(visibleItems, totalProducts)} of {totalProducts} products
            </Text>
          </div>
        </Panel>
      </Collapse>
    )
  );
};

export default ProductListView;
