import React from 'react'
import PersistentDrawerLeft from '../../../reusable/admin/Main'
import { Container, AttributeContainer, TextFieldCustom, FormDiv, ButtonCust } from "../styles/Product.style";
import { Form, Input, Button, Row, Col, message } from "antd";
import {
    TableContainer, Table, TableHead, IconButton, Box,
    TableRow, TableCell, TableBody, TablePagination, CircularProgress, Chip,
    Typography, TextField
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { BUTTON_STYLE } from "../../../../constants/style";
import { create_department } from '../services/departmentapi';

const Department = () => {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [loading, setLoading] = React.useState(false);

    const onFinish = async (values: any) => {
        try {
            setLoading(true);
            const response = await create_department({ name: values.name });
            // message.success(`Department created successfully: ${response.name}`);
            navigate(`/admin/department_list`);
            form.resetFields();
        } catch (error: any) {
            // console.error("Error creating department:", error);
            const errorMessage = error.response?.data?.message || "Failed to create department. Please try again.";
            message.error(errorMessage);
        } finally {
            setLoading(false);
        }
    };

    const onFinishFailed = (errorInfo: any) => {
        // message.error("Failed to submit form. Please check your input.");
        // console.log("Failed:", errorInfo);
    };
    const handleCreate = () => {
        navigate(`/admin/department_list`);
    };

    return (
        <>
            <PersistentDrawerLeft>
                <FormDiv>
                    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                        <Typography variant="h6">Department</Typography>
                        <ButtonCust onClick={handleCreate} sx={BUTTON_STYLE}>
                            Department List
                        </ButtonCust>
                    </Box>
                    <Form
                        form={form}
                        name="nameForm"
                        layout="vertical"
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        style={{ maxWidth: "600px", margin: "0 auto" }}
                    >
                        <Row gutter={16}>
                            {/* Name Input */}
                            <Col xs={24} sm={16}>
                                <Form.Item
                                    label="Department Name"
                                    name="name"
                                    rules={[
                                        { required: true, message: "Please enter department name!" },
                                        { min: 3, message: "Name must be at least 3 characters long." },
                                    ]}
                                >
                                    <Input placeholder="Enter Department Name" />
                                </Form.Item>
                            </Col>

                            {/* Submit Button */}
                            <Col xs={24} sm={8}>
                                <Form.Item>
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        style={{ width: "100%", marginTop: "32px" }}
                                        disabled={loading}
                                    >
                                        {loading ? <CircularProgress size={24} color="inherit" /> : "Submit"}

                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </FormDiv>
            </PersistentDrawerLeft>
        </>
    )
}

export default Department