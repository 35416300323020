import React from 'react';
import Department_listUI from '../../components/admin/department/components/Department_list';
import PersistentDrawerLeft from "../../components/reusable/admin/Main";
const Department_listTable = () => {
  return (
    <div>
    <PersistentDrawerLeft>
      <Department_listUI/>
    </PersistentDrawerLeft>
    </div>
  );
};

export default Department_listTable;