import React from 'react';
import { Table, Tag } from 'antd';

const NotExitsProductsModal = ({productData}:any) => {
    const columns = [
        {
          title: 'Product Name',
          dataIndex: ['attribute', 'name'],
          key: 'name',
        },
        {
          title: 'Product Code',
          dataIndex: ['attribute', 'companyProductCode'],
          key: 'companyProductCode',
        },
        {
          title: 'Unit',
          dataIndex: ['attribute', 'unit'],
          key: 'unit',
        },
        {
          title: 'Quantity',
          dataIndex: ['attribute', 'qty'],
          key: 'qty',
        },
        {
          title: 'Company Name',
          dataIndex: ['attribute', 'company_name'],
          key: 'company_name',
        },
        {
          title: 'Status',
          dataIndex: ['attribute', 'isNotExits'],
          key: 'status',
          render: (isNotExits: boolean) => (
            <Tag color={isNotExits ? 'red' : 'green'}>
              {isNotExits ? 'Not Available' : 'Available'}
            </Tag>
          ),
        },
      ];
    return (
        <>
         <div style={{ padding: '20px' }}>
            {
                productData && productData.length > 0  &&
                 <Table
                columns={columns}
                dataSource={productData}
                rowKey={(record:any,index:any) => "notexits"+index}
                bordered
                pagination={false}
                 size="small"
              />
            }
    
         </div>
        </>
    )
}
export default NotExitsProductsModal;