import React from 'react';
import UserAddUI from "../../components/admin/users/components/User";
import PersistentDrawerLeft from "../../components/reusable/admin/Main";
const UserAdd = () => {
  return (
    <div>
      <PersistentDrawerLeft>
      <UserAddUI/>
      </PersistentDrawerLeft>
    </div>
  );
};

export default UserAdd;