import axios from "axios";
import {restApi} from "../../../../constants/api";


export const create_department = async (payload:any) => {
    const response = await restApi(`/department`,"POST",payload);
    return response 
};

export const department_list = async (payload:any) => {
    const response = await restApi(`/department`,"GET",payload);
    return response 
};

export const department_editapi = async (departmentId:string = "") => {
    const response = await restApi(`department/${departmentId}`,"GET");
    return response 
};
export const department_update = async (payload:any,departmentId:string) =>{
    const response = await restApi(`/department/${departmentId}`,"PATCH",payload);
    return response 
}