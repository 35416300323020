import React from 'react';
import UserListUI from "../../components/admin/users/components/Userlist";
import PersistentDrawerLeft from "../../components/reusable/admin/Main";
const UserList = () => {
  return (
    <div>
      <PersistentDrawerLeft>
      <UserListUI/>
      </PersistentDrawerLeft>
    </div>
  );
};

export default UserList;