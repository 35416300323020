import React from 'react';
import { Route, Routes,Navigate } from 'react-router-dom';
import Login from "./login";
import Dashboard from "./admin/dashboard";
// import DashboardWithGst from "./admin/dashboard_with_gst";
import Product from './admin/product';
import AdminProductList from "./admin/productList";
import AdminProductEdit from "./admin/productEdit";
import AdminOrderCreate from "./admin/order";
import AdminOrderList from "./admin/orderList";
import AdminOrderDetail from "./admin/orderDetail";
import ProductImport from './admin/productImport';
import AdminOrderEdit from "./admin/orderEdit";
import Footer from '../components/reusable/admin/Footer';
import Department_listTable from './admin/Department_list';
import DepartmentADD from './admin/Department';
import DepartmentEdit from './admin/DepartmentEdit';
import UserAdd from './admin/addUser';
import UserList from './admin/userList';
import UserEdit from './admin/userEdit';
// import AdminUserList from "./admin/userList";
// import AdminUserAdd from "./admin/addUser";

const AppRoutes = () => {
  const token = localStorage.getItem('token');
  return (
    <>
    <Routes>
    <Route path="/" element={token ? <AdminOrderList /> : <Login />} />
      <Route path="/" element={<Login />} />
      {/* <Route path="/product_list" element={<ProductList/>} /> */}
      {/* <Route path="/product_detail/:id" element={<ProductDetail/>} /> */}
      {/* <Route path="/cart" element={<Cart/>} /> */}
      <Route path="/login" element={<Login/>} />
      {/* <Route path="/register" element={<Register/>} /> */}
      <Route path="/admin" element={token ? <Dashboard /> : <Navigate to="/login" />} />
      {/* <Route path="/admin/dashboard_with_gst" element={<DashboardWithGst/>} /> */}
      <Route path="/admin/product" element={ token ? <Product /> : <Navigate to="/login" />} />
      <Route path="/admin/product_list" element={ token ? <AdminProductList /> : <Navigate to="/login" />} />
      <Route path="/admin/product_edit/:productId" element={ token ? <AdminProductEdit /> : <Navigate to="/login" />} />
      <Route path="/admin/quotation/create" element={ token ? <AdminOrderCreate /> : <Navigate to="/login" />} />
      <Route path="/admin/quotation_list" element={ token ? <AdminOrderList /> : <Navigate to="/login" />} />
      <Route path="/admin/quotation_list/:startDateFilter/:endDateFilter" element={ token ? <AdminOrderList /> : <Navigate to="/login" />} />
      <Route path="/admin/quotation_edit/:orderId" element={ token ? <AdminOrderDetail /> : <Navigate to="/login" />} />
      <Route path="/admin/quotation_update/:orderId" element={ token ? <AdminOrderEdit /> : <Navigate to="/login" />} />
      <Route path="/admin/product_import" element={ token ? <ProductImport /> : <Navigate to="/login" />} />
      
      <Route path="/admin/department_list" element={token ? <Department_listTable /> : <Navigate to="/login" />} />
      <Route path="/admin/department" element={token ? <DepartmentADD /> : <Navigate to="/login" />} />
      <Route path="/admin/departmentUpdate/:departmentId" element={token ? <DepartmentEdit /> : <Navigate to="/login" />} />

      <Route path="/admin/User_list" element={token ? <UserList /> : <Navigate to="/login" />} />
      <Route path="/admin/User" element={token ? <UserAdd /> : <Navigate to="/login" />} />
      <Route path="/admin/userListUpdate/:usersId" element={token ? <UserEdit /> : <Navigate to="/login" />} />
    </Routes>
    <br/>
    <Footer/>
    </>
  );
};

export default AppRoutes;