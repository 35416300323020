import axios from "axios";
import {restApi} from "../../../../constants/api";


export const create_users = async (payload:any) => {
    const response = await restApi(`/users`,"POST",payload);
    return response 
};

export const users_list = async (payload:any) => {
    const response = await restApi(`/users`,"GET",payload);
    return response 
};

export const users_editapi = async (usersId:string = "") => {
    const response = await restApi(`users/${usersId}`,"GET");
    return response 
};
export const users_update = async (payload:any,usersId:string) =>{
    const response = await restApi(`/users/${usersId}`,"PATCH",payload);
    return response 
}