import React, { useState } from "react";
import {TextField} from "@mui/material";
import OrderDepartmentQuotation from "./OrderDepartmentQuotation";
import "./DepartmentTabs.css";

const DepartmentTabs = ({departments,gstRates,companies,units,setDepartment,duplicates,alreadyAddedQuotationPro}:any) => {
  const [activeTab, setActiveTab] = useState(0);

  const tabs = departments.map((e:any)=>{
         return {
            id:e.id,
            label:e.name,
            content:e.name
         }
  });
 

  return (
    <div className="custom-tabs">
      {/* Tab Header */}
      <div className="tab-header">
        {tabs.map((tab:any, index:any) => (
          <button
            key={index}
            className={`tab-button ${activeTab === index ? "active" : ""}`}
            onClick={() => setActiveTab(index)}
          >
           <label   className={`tab-label ${activeTab === index ? "active" : ""}`}> {tab.label}</label>
          </button>
        ))}
      </div>
    
      {/* Tab Content */}
      <div className="tab-content">
     

            <OrderDepartmentQuotation 
             gstRates={gstRates} companies={companies}
             units={units}
             orderProductss={departments && departments[activeTab].orderProducts ? 
                departments[activeTab].orderProducts : []
             }
             department={departments[activeTab]}
             setDepartment={setDepartment}
             departments={departments}
             duplicates={duplicates}
             alreadyAddedQuotationPro={alreadyAddedQuotationPro}
             />
      </div>
    </div>
  );
};

export default DepartmentTabs;
