import React, { useEffect, useState } from "react";
import PersistentDrawerLeft from "../../../reusable/admin/Main";
import { FormDiv, ButtonCust } from "../styles/Product.style";
import { Form, Button, Table } from "antd";
import { Box, Typography } from "@mui/material";
import { EditOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { BUTTON_STYLE } from "../../../../constants/style";
import { users_list } from "../services/users";

const styletabletext = {
  fontSize: '14px'
}
interface Users {
  id: number;
  fullName: string;
  mobileNo: number;
  email: any;
  addressInfo: any;
  GSTnumber: any;
}
const Userlist = () => {
  const navigate = useNavigate();

  // Updated data source with new fields
  const [dataUserSource, setDataUserSource] = useState<Users[]>([]);

  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        setLoading(true);
        const response: any = await users_list({});
        // const usersData = Array.isArray(response.data) ? response.data : [];
        setDataUserSource(response.data);
      } catch (error) {
        // console.error("Error fetching Users data:", error);
        setDataUserSource([]);
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  // Updated columns for new fields
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      render: (text: string | number) => (
        <Typography style={styletabletext}>{text}</Typography>
      ),
    },
    {
      title: "Full Name",
      dataIndex: "fullName",
      key: "fullName",
      render: (text: string) => <Typography style={styletabletext}>{text}</Typography>,
    },
    {
      title: "Mobile No",
      dataIndex: "mobileNo",
      key: "mobileNo",
      render: (text: string) => <Typography style={styletabletext}>{text}</Typography>,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (text: string) => <Typography style={styletabletext}>{text}</Typography>,
    },
    {
      title: "Address Info",
      dataIndex: "addressInfo",
      key: "addressInfo",
      render: (text: string) => <Typography style={styletabletext}>{text}</Typography>,
    },
    {
      title: "GST No",
      dataIndex: "GSTnumber",
      key: "GSTnumber",
      render: (text: string) => <Typography style={styletabletext}>{text}</Typography>,
    },
    {
      title: "Action",
      key: "action",
      render: (_: any, record: { id: number }) => (
        <Button
          type="text"
          icon={<EditOutlined />}
          onClick={() => navigate(`/admin/userListUpdate/${record.id}`)}
        >
          Edit
        </Button>
      ),
    },
  ];

  const handleCreate = () => {
    navigate(`/admin/user`);
  };


  return (
    <FormDiv>
      {/* Header Section */}
      <Box sx={{ display: "flex", justifyContent: "space-between", marginBottom: 2 }}>
        <Typography variant="h6">User List</Typography>
        <ButtonCust onClick={handleCreate} sx={BUTTON_STYLE}>
          Add
        </ButtonCust>
      </Box>

      {/* Table Section */}
      <Table
        dataSource={dataUserSource}
        columns={columns}
        bordered
        rowKey="id" // Key to uniquely identify rows
        pagination={{ pageSize: 10 }} // Pagination with 10 rows per page
        size="small"
        style={{ border: "1px solid #ddd", borderRadius: "8px" }} // Adding a border
      />
    </FormDiv>
  );
};

export default Userlist;
