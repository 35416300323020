import axios from "axios";
import {restApi} from "../../../../constants/api";
export const get_product_search = async (search:string = "",companyId:any,productId?:any,unitId?:any,unitQty?:any) => {
        const response = await restApi(`product/admin/search?companyId=${companyId}&productId=${productId}&unitId=${unitId}&unitQty=${unitQty}`,"GET",{search:search});
        return response 
};

export const get_product_search_with_quotation = async (search:string = "",quotationId:any,companyId:any,productId?:any,unitId?:any,unitQty?:any) => {
        const response = await restApi(`product/admin/search_with_quotation/${quotationId}?companyId=${companyId}&productId=${productId}&unitId=${unitId}&unitQty=${unitQty}`,"GET",{search:search});
        return response 
};

export const create_order = async (payload:any) => {
        const response = await restApi(`quotation`,"POST",payload);
        return response 
};

export const quotation_list = async (page:number, limit:number, startDate:string | null  = "", endDate:string | null = "" ,
        searchInput: string | null = "",quotationId: string | null = "",orderStatus:string | null = "",
         status:string | null = ""
) => {
        const response = await restApi(`quotation?page=${page}&limit=${limit}&startDate=${startDate}&endDate=${endDate}&quotationId=${quotationId}&orderStatus=${orderStatus}&status=${status}`,"GET",{search:searchInput});
        return response 
};

export const order_list_total = async (startDate:string | null  = "", endDate:string | null = "" ,
        searchInput: string | null = "",quotationId: string | null = "", orderStatus:string | null = "",
         status:string | null = "") => {
        const response = await restApi(`quotation/total?startDate=${startDate}&endDate=${endDate}&quotationId=${quotationId}&orderStatus=${orderStatus}&status=${status}`,"GET",{search:searchInput});
        return response 
};

export const order_detail = async (orderId:string = "") => {
        const response = await restApi(`quotation/${orderId}`,"GET");
        return response 
};

export const order_status_update = async (payload:any,orderId:string) =>{
        const response = await restApi(`/quotation/${orderId}/status`,"PATCH",payload);
        return response 
}

export const get_units = async () =>{
        const response = await restApi(`/units/conversions`,"GET");
        return response 
}
export const get_gst_rates = async () =>{
        const response = await restApi(`/gst`,"GET");
        return response 
}

export const get_user = async () =>{
        const response = await restApi(`/users/search`,"GET");
        return response 
}

export const create_user = async (payload:any) =>{
        const response = await restApi(`/users/create`,"POST",payload);
        return response 
}
 

export const fetch_all_products = async () =>{
        const response = await restApi(`/product/admin/search/all`,"GET");
        return response 
}

export const update_order  = async (payload:any,quotationId:any) => {
       const response = await restApi(`/quotation/${quotationId}`,"PATCH",payload);
       return response 
}
 

export const insert_batch_quotation  = async (payload:any,quotationId:any) => {
        const response = await restApi(`/quotation/add_attribute/${quotationId}`,"POST",payload);
        return response 
 }
  

 export const fetch_batch_product  = async (data:any) => {
        const response = await restApi(`/product/maniplmanipulation`,"POST",data);
        return response 
 }
  
 export const fetch_batch_update_product  = async (data:any,orderId:any) => {
        const response = await restApi(`/product/maniplmanipulation/${orderId}`,"POST",data);
        return response 
 }
  
 export const delete_product = async (quotationId:any,rowId:any,finalPrice:any) => {
        const response = await restApi(`quotation/${quotationId}/${rowId}/${finalPrice}`,"DELETE",{});
        return response 
};

export const fetch_departments = async () => {
        const response = await restApi(`department`,"GET",{});
        return response 
};

