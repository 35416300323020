import React from 'react'
import { Col, Row, Typography } from 'antd'

interface TermsconditionsProps {
  style?: React.CSSProperties;
  selecteduitable: string;
  order: any;
}

const Termsconditions: React.FC<TermsconditionsProps> = ({ style, selecteduitable, order }) => {
  const styles = {
    ttitletex: {
      fontSize: '15px'
    },
    boldText: {
      fontWeight: 'bold',
      fontSize: '15px',
    },
  };

  return (
    <>
      <Typography.Title style={{
        textAlign: 'center',
        // backgroundColor: '#64713c',
        color: 'white',
        marginTop: '10px',
        fontSize: '15px',
        padding: '7px 0px',
        ...style,
      }}>TERMS & CONDITIONS</Typography.Title>
      <Row>
        <Col xs={15} sm={15} md={15} lg={15} xl={15}>
          {
            order.termsAndConditions ?
              <div dangerouslySetInnerHTML={{ __html: order.termsAndConditions }} />
              :
              <>
                <Typography.Text>
                  <span style={styles.boldText}>{`${1}}`}</span> <span style={styles.ttitletex}>Rates are Final.</span>
                </Typography.Text> <br />
                <Typography.Text>
                  <span style={styles.boldText}>{`${2}}`}</span> <span style={styles.ttitletex}>GST will be Extra as per Government Rule.</span>
                </Typography.Text> <br />
                <Typography.Text>
                  <span style={styles.boldText}>{`${3}}`}</span> <span style={styles.ttitletex}>Delivery at Your Site.</span>
                </Typography.Text> <br />
                <Typography.Text>
                  <span style={styles.boldText}>{`${4}}`}</span> <span style={styles.ttitletex}>Delivery period 10 to 15 days.</span>
                </Typography.Text> <br />
                <Typography.Text>
                  <span style={styles.boldText}>{`${5}}`}</span> <span style={styles.ttitletex}>Payment 50% Advance Along With Purchase Order. Remaining against delivery.</span>
                </Typography.Text>
              </>
          }
        </Col>
        {/*Bank Details*/}
        <Col xs={9} sm={9} md={9} lg={9} xl={9}>
          {
            order.bankDetails ?
              <div dangerouslySetInnerHTML={{ __html: order.bankDetails }} />
              :
              <>
                <Typography.Text>
                  <span style={styles.boldText}>*</span> <span style={styles.ttitletex}> <span style={styles.boldText}>Bank Name</span>: HDFC Bank</span>
                </Typography.Text> <br />
                <Typography.Text>
                  <span style={styles.boldText}>*</span> <span style={styles.ttitletex}> <span style={styles.boldText}>Account No</span>: 50200042167709</span>
                </Typography.Text> <br />
                <Typography.Text>
                  <span style={styles.boldText}>*</span> <span style={styles.ttitletex}> <span style={styles.boldText}>IFSC Code</span>: HDFC0004800 </span>
                </Typography.Text> <br />
                {/* <Typography.Text>
                  <span style={styles.boldText}>*</span> <span style={styles.ttitletex}> <span style={styles.boldText}>MICR Code</span>: 416240007</span>
                </Typography.Text> <br /> */}
                <Typography.Text>
                  <span style={styles.boldText}>*</span> <span style={styles.ttitletex}> <span style={styles.boldText}>Branch</span>: New Mahadwar Road</span>
                </Typography.Text>
              </>
          }

        </Col>

        <Col xs={14} sm={14} md={14} lg={14} xl={15}></Col>
        <Col xs={10} sm={10} md={10} lg={10} xl={9}>
          <br />
          <Typography.Text>Thanking You</Typography.Text> <br />
          <Typography.Text style={styles.boldText}>For {selecteduitable === "SOHAM FIRM" ? "Soham Scientific" : "Unique Biological & Chemicals"}</Typography.Text><br /><br />
          <Typography.Text style={styles.boldText}>Kolhapur</Typography.Text><br />
        </Col>
      </Row>
    </>
  )
}

export default Termsconditions;
