

export function calculatePrice(
  e:any,
  quotationQty:any  ) {
   e.discountValue = ( Number(e.price) * ( Number(e.discount) / 100)).toFixed(2) ;
   e.discountAmount = (Number(e.price) -  Number(e.discountValue)).toFixed(2);
   e.gstValue = (Number(e.discountAmount) * (Number(e.gst_percentage) / 100)).toFixed(2) ;
   e.gstAmount = (Number(e.discountAmount) + Number(e.gstValue)).toFixed(2) ;
   e.totalPrice = (Number(quotationQty) * Number(e.gstAmount)).toFixed(2) ;

    return e;
  }
  
  

export function convertNumberToWords(num: number): string {
    const a: string[] = [
        '', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine', 'Ten', 'Eleven',
        'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen'
    ];
    const b: string[] = ['', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];

    const convertHundreds = (n: number): string => {
        if (n === 0) return '';
        if (n < 20) return a[n] + ' ';
        if (n < 100) return b[Math.floor(n / 10)] + ' ' + a[n % 10] + ' ';
        return a[Math.floor(n / 100)] + ' Hundred ' + convertHundreds(n % 100);
    };

    const convertToWords = (n: number): string => {
        if (n === 0) return 'Zero';

        let output = '';

        if (Math.floor(n / 100000) > 0) {
            output += convertHundreds(Math.floor(n / 100000)) + 'Lakh ';
            n %= 100000;
        }

        if (Math.floor(n / 1000) > 0) {
            output += convertHundreds(Math.floor(n / 1000)) + 'Thousand ';
            n %= 1000;
        }

        output += convertHundreds(n);
        return output.trim();
    };

    return convertToWords(num) + ' Only';
}



 export  function sanitizeHTMLContent(html:any) {
    const tempDiv:any = document.createElement("div");
    tempDiv.innerHTML = html;
    return tempDiv.textContent.trim() ? html : "";
  }