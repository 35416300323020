import React from 'react';
import DepartmentUI from '../../components/admin/department/components/Department';
import PersistentDrawerLeft from "../../components/reusable/admin/Main";
const DepartmentADD = () => {
  return (
    <div>
    <PersistentDrawerLeft>
      <DepartmentUI/>
    </PersistentDrawerLeft>
    </div>
  );
};

export default DepartmentADD;