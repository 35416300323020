import React, { useEffect, useState } from "react";
import PersistentDrawerLeft from "../../../reusable/admin/Main";
import { FormDiv, ButtonCust } from "../styles/Product.style";
import { Form, Input, Button, Row, Col, message } from "antd";
import { Box, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { BUTTON_STYLE } from "../../../../constants/style";
import { department_editapi, department_update } from "../services/departmentapi";


const DepartmentUpdate = () => {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const { departmentId } = useParams();
    const [isEdit, setIsEdit] = useState(false);
    const [datadepartmentId, setdepartmentId] = useState<any>({});

    useEffect(() => {
        if (departmentId) {
            fetchdepartmentDetail();
        }
    }, [departmentId]);

    const fetchdepartmentDetail = async () => {
        const response: any = await department_editapi(departmentId);
        const department = response?.data?.data || {};
        setdepartmentId(department);
        if (department) {
            form.setFieldsValue({
                name: department.name,
            });
            setIsEdit(true);
        }
    };

    const onFinish = async (values: any) => {
        if (isEdit && departmentId) {
            // If editing, call the update API
            try {
                const response = await department_update(values, departmentId);
                if (response) {
                    message.success("Department updated successfully!");
                } else {
                    message.error("Failed to update the department.");
                }
            } catch (error) {
                // console.error("Error updating department:", error);
                message.error("An error occurred while updating the department.");
            }
        } else {
            message.success("New department added successfully!");
        }

        form.resetFields();
        navigate("/admin/department_list");
    };


    const onFinishFailed = (errorInfo: any) => {
        // console.error("Failed:", errorInfo);
    };

    const handleBack = () => {
        navigate("/admin/department_list");
    };

    return (
        <PersistentDrawerLeft>
            <FormDiv>
                <Box sx={{ display: "flex", justifyContent: "space-between", marginBottom: 2 }}>
                    <Typography variant="h6">
                        {isEdit ? "Edit Department" : "Create Department"}
                    </Typography>
                    <ButtonCust onClick={handleBack} sx={BUTTON_STYLE}>
                        Department List
                    </ButtonCust>
                </Box>
                <Form
                    form={form}
                    name="departmentForm"
                    layout="vertical"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    style={{ maxWidth: "600px", margin: "0 auto" }}
                >
                    <Row gutter={16}>
                        <Col xs={24} sm={16}>
                            <Form.Item
                                label="Name"
                                name="name"
                                rules={[
                                    { required: true, message: "Please input the department name!" },
                                    { min: 3, message: "Name must be at least 3 characters long." },
                                ]}
                            >
                                <Input placeholder="Enter department name" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={8}>
                            <Form.Item>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    style={{ width: "100%", marginTop: "32px" }}
                                >
                                    {isEdit ? "Update" : "Submit"}
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </FormDiv>
        </PersistentDrawerLeft>
    );
};

export default DepartmentUpdate;
